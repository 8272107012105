.rowOpenRoles {
  justify-content: center;
  align-items: center;
  margin: 0.25rem 0.5rem;
  border-radius: 120px;
  background: var(--orange---orange-100);
  padding: 0 0.5rem 0 0.5rem;
  font-size: 12px;
  color: var(--white);
  font-style: normal;
  font-weight: 600;
  font-family: 'DM Sans', sans-serif;
  display: inline-block;
}

.columnTableJobTitle > p {
  color: var(--neutral---gray-900);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  font-family: 'DM Sans', sans-serif;
}

.columnTableJobTitle p:nth-child(2) {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.0625rem;
  color: var(--neutral---gray-700);
}

.containerProgressBar {
  display: 'flex';
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
}

.containerProgressBar p {
  color: var(--neutral---gray-900);
  text-align: left;
  font-size: 0.75rem;
  font-weight: 400;
}

.progressBar {
  padding-top: 0.25rem;
}

.footerTable {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  align-items: flex-start;
}

.footerTable p a {
  color: var(--brand---stellarworx--dark--blue);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
}
