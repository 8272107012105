.parentstarsGeographyContainer {
  height: 33rem;
}

.starsGeographyContainer {
  display: flex;
  height: 100%;
  font-family: DM Sans, Euclid Circular B, helvetica, arial, sans-serif;
}

.starsGeographyMap {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.lineProgress {
  margin-top: 1rem;
}

.footerGeographyMap {
  width: 329px;
  background: var(--white);
  height: 17px;
  bottom: 24px;
  left: 24px;
  z-index: 1000;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0.25rem;
  font-family: DM Sans, Euclid Circular B, helvetica, arial, sans-serif;
  box-shadow: 0px 2px 6px 0px rgba(61, 61, 61, 0.1),
    0px 0px 4px 0px rgba(61, 61, 61, 0.06);
  border-radius: 8px;
  position: absolute;
}

.footerGeographyMap p {
  color: var(--brand---stellarworx--dark--blue);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.06rem;
}

.boxGradientBar {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.boxGradientBar p {
  color: var(--light-gray-gray-600);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.06rem;
}

.gradientBar {
  width: 3.5rem;
  height: 0.6875rem;
  border-radius: 0.75rem;
  background: linear-gradient(270deg, #4c0759 0%, #f9d8fd 100%);
}

.circle {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem;
}

.circle p {
  color: var(--light-gray-gray-600);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.06rem;
}

.containerToggleButton {
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.containerToggleButton p {
  color: var(--neutral---gray-900);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}
