.custom-icon {
  background-color: #0565e3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  width: 30px;
  height: 30px;
}
.custom-ca-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.8rem;
  font-family: DM Sans, Euclid Circular B, helvetica, arial, sans-serif;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  pointer-events: none;
}

.button-select-state {
  width: '43px';
  height: '21px';
  background-color: '#b186b9';
  padding: '2px';
  font-weight: 'bold';
  border-radius: '25px';
  font-style: 'Bold';
  font-size: 12px;
}
.leaflet-container .leaflet-control-attribution {
  display: none;
}

.circular-icon {
  pointer-events: none;
  font-family: DM Sans, Euclid Circular B, helvetica, arial, sans-serif;
}

.circle {
  width: 32px;
  height: 18px;
  border-radius: 25px;
  padding: 2px;
  display: flex;
  font-weight: bold;
  font-size: 11px;
  justify-content: center;
  align-items: center;
}

.leaflet-interactive {
  fill-opacity: 1;
}

.leaflet-control-zoom {
  border-radius: 10px;
  width: 36px !important  ;
  height: 72px !important;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  justify-content: space-evenly;
  box-shadow: 0px 1px 8px 0px #3741511f !important;
  border: 0px !important;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  width: 24px !important  ;
  height: 24px !important;
}

.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
  background-color: #fd000000 !important;
  color: rgb(71, 71, 71) !important;
}

.leaflet-control-zoom-in {
  background-image: url('../icons/map-zoom-out.svg') !important;
  background-repeat: no-repeat;
  background-size: contain;
  border-bottom: 0px !important;
}

.leaflet-control-zoom-out {
  background-image: url('../icons/map-zoom-in.svg') !important;
  background-repeat: no-repeat;
  background-size: contain;
}
.leaflet-control-zoom-out span,
.leaflet-control-zoom-in span {
  display: none;
}
