.donutContainer {
    display: flex;
    gap: 1.5rem;
    margin-top: 32px;

}
.donutGroup{
    display:flex;
    gap: 1.5rem;
    align-items: center;
    height: 100%;
    width: 351px;
}

.boxDonut{
  display: flex;
  flex-direction: column;
  gap: '1.5rem';
  height: '10.6rem'
}

.boxDonut strong {
    color: var(--neutral---gray-900);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem; 
}

.boxDonut p {
    color: var(--neutral---gray-900);

    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    margin-bottom: 1.5rem;


}


.donutGroup > div{
   height: 8rem !important;
   width: 8rem !important;
}
