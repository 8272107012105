.map-container {
    background-color: rgba(255, 255, 255, 0.884);
    position: absolute;
    height: 432px;
    width: 772px;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.map-container a {
    text-decoration: underline;
    color: black;
}
