
:root {
  --white: #fff;
  --orange-500:#FFB81C;
  --neutral---black: #000000;
  --neutral---gray-900: #3D3D3D;
  --neutral---gray-700: #777777;
  --neutral---gray-500: #C6C6C6;
  --neutral---gray-400: #E5E7EB;
  --neutral---gray-300: #F1F1F1;
  --neutral---gray-200: #F5F5F7;
  --neutral---gray-100: #F9F9F9;
  --neutral---gray-50: #FCFCFC;
  --purple---purple-125: #782D86;
  --purple---purple-100: #8C4799;
  --purple---purple-75: #A975B3;
  --purple---purple-50: #C5A3CC;
  --purple---purple-25: #E2D1E5;
  --green---green-125: #55AE31;
  --green---green-100: #6CC24A;
  --green---green-75: #91D177;
  --green---green-50: #B5E1A4;
  --green---green-25: #DAF0D2;
  --orange---orange-125: #FF651A;
  --orange---orange-100: #FF7F41;
  --orange---orange-75: #FF9F71;
  --orange---orange-50: #FFBFA0;
  --orange---orange-25: #FFDFCF;
  --brand---stellarworx--green: #4298B5;
  --brand---stellarworx--warning: #E03C31;
  --brand---stellarworx--dark--blue: #1D4289;
  --light-gray-gray-600: #494F5A;

  --brand-stellarworx-purple: #8E4B9D
}

*{
  margin: 0;
  padding: 0;
}

body {

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'DM Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
