.requiredRadio {
    color: red !important;
}

.requiredText {
    border-width: 1px !important;
    border-style: solid !important;
    border-color: red !important;
}

.wrapperLogin {
    display: flex;
    flex-direction: column;
    background-color: var(--orange-500);
    height: 100vh;
}
@media screen and (max-height:700px) {   
    .wrapperLogin{
        height: auto;
    } 
}

.main{
    margin-top: 5rem;
    flex-grow: 1;
   
}

.content{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--orange-500);
}

.boxForm{
    background-color: var(--white);
    width: 22.5rem;

    border-radius: 16px;
    margin-top: 2.5rem;
    padding: 1.5rem;
}


.boxForm form {
    width: 100%;
}

.boxForm button {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    background: var(--orange---orange-100);
    border-radius: 120px;
    border: none;
    margin-top: 1.5rem;
    color: var(--white);
    font-size: 1rem;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.16px;
    cursor: pointer;
    
}

.boxForm form strong{
    font-weight: 600;
    line-height: 20px;  
}

.infoSection{
    display: flex;
    flex-direction: column;
    gap: 1.5rem
}

.inputForm{
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: 1rem;
    padding: 0 0 0 0.3rem;
    width: 100%;

}

.customInput{
    margin-left: 1rem;
    background-color: #F5F5F7;
    color: var(--neutral---gray-700);
    font-size: 0.75rem;
    margin-top: 0.5rem;
    border-radius: 1.75rem;
    padding: 0.375rem 0.75rem;
    height: 28px;
    width: 100%;
    box-sizing: border-box;
    border: none;
}

.organizationNameInput{
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    padding-right: 0.8rem;
}

.organizationNameInput input{
    background-color: #F5F5F7;
    margin-top: 0.5rem;
    border-radius: 120px;
    padding: 0.5rem 1rem;
    height: 36px;
    width: 100%;
    box-sizing: border-box;
    border: none;

}

.organizationNameInput strong{
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.0625rem; /* 121.429% */
}

.organizationNameInput input::placeholder {
    color: var(--neutral---gray-900);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem; 
}
