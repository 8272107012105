
.container{
    display: flex;
    padding: 2px 0px;
    align-items: flex-start;
  
}
.container label {
    display: flex; 
    align-items: center;
    color: #3D3D3D;
    font-size: 14px;
    font-style: normal;
    line-height: 17px;
}

.container label input{
    margin-right: 0.375rem;
    width: 16px;
    height: 16px;
}
