.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.bold-text {
  font-weight: bold;
  font-size: 1.125rem;
  cursor: pointer;
}

.bar {
  color: #ff651a;
  font-weight: bold;
  font-size: 1.125rem;
}

.content {
  margin-top: 10px;
  border-bottom: 1px solid #bcbaba;
  height: 23px;
}

.title-text {
  font-weight: bold;
  margin-top: 10px;
  font-size: 0.875rem;
}

.wrapper-percents {
  display: flex;
  align-items: center;
  width: 50px;
  justify-content: flex-end;
  gap: 5px;
}

.work-status-column {
  width: 170px;
}

.column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 165px;
  height: 20px;
}
.flex-container {
  display: flex;
  justify-content: space-between;
}

.title-container {
  margin-top: 10px;
  width: 100%;
}
.flex-item {
  font-size: 17px;
  font-weight: 500;
}
.title-column {
  border-top: 1px solid #bcbaba;
  margin-top: 5px;
}
.total-work-force {
  margin: 0;
  font-size: 17px;
  font-weight: 490;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.work-status {
  margin: 0;
  font-size: 1rem;
  font-weight: 480;
}

.percentage-country {
  font-size: 1rem;
  font-weight: 580;
  text-align: end;
  color: #ff651a;
  min-width: 25px;
}

.region-percent {
  width: 100%;
  text-align: end;
  font-size: 15px;
  font-weight: 480;
}

.link-to-state {
  border: none;
  background: none;
  cursor: pointer;
}
