.searchComponent {
  font-family: 'DM Sans', sans-serif !important;
  position: relative;
  width: 400px !important;
  margin: auto;
  z-index: 1000;
}

.searchInput {
  font-family: 'DM Sans', sans-serif !important;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: transparent;
}

.searchDropdown {
  position: absolute;
  width: 500px;
  right: 0;
  max-height: 500px;
  min-height: 50px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: block;
}

.searchDropdown.active {
  display: block;
}

.searchGroup {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #dfdfdf;
}

.searchGroup h2 {
  font-size: 14px;
  color: #333;
  padding: 8px 10px;
  margin: 0;
  background-color: #f7f7f7;
  border-bottom: 1px solid #eee;
}

.resultCount {
  background-color: #e4e4e4;
  color: rgb(65, 65, 65);
  border-radius: 35%;
  width: 25px;
  height: 20px;
  font-size: 12px;
  font-weight: 600;
  padding: 0px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto; /* Aligns to the right */
}

.searchGroup li {
  padding: 8px 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  color: #333;
}

.searchGroup li:hover {
  background-color: #f7f7f7;
}

/* Scroll bar styles */
.searchDropdown::-webkit-scrollbar {
  width: 5px;
}

.searchDropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.searchDropdown::-webkit-scrollbar-thumb {
  background: #888;
}

.searchDropdown::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.inputWithIcons {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-radius: 120px;
  border: #f1f1f1;
  background: #f1f1f1;
  width: 324px;
  padding: 0px 10px;
}

.searchInput {
  flex-grow: 1;
  border: none;
  padding-left: 10px;
}

.searchInput::placeholder {
  color: #c6c6c6; /* Color de tu preferencia */
  opacity: 1;
}

.searchIcon,
.arrowIcon {
  color: #757575;
}

.searchInput:focus {
  outline: none;
}

@media (max-width: 768px) {
  .searchComponent {
    width: 100%;
  }
}
