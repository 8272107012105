@tailwind base;
@tailwind components;
@tailwind utilities;

.leaflet-container {
  height: inherit;
  background-color: #fafaf8;
}

.national-container {
  background-color: rgb(140, 71, 153, 0.04);
  height: inherit;
}

.map-info {
  width: 425px;
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: right;
  top: 8px;
  right: 8px;
  bottom: 8px;
  padding: 5px 10px;
  font: 13px/1 sans-serif;
  -moz-box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.2);
  background: #fff;
}

.map-polygon-label {
  background: none !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0px !important;
  box-shadow: 0 0px 0px !important;
  font-family: 'DM Sans', sans-serif !important;
  text-shadow: -0.75px -0.75px 0 #3a3b3c, 0.75px -0.75px 0 #3a3b3c,
    -0.75px 0.75px 0 #3a3b3c, 0.75px 0.75px 0 #3a3b3c !important;
}

.map-tooltip-row {
  display: flex;
}

.map-tooltip-col {
  flex: 50%;
  padding: 10px;
}

a {
  text-decoration: none;
}

.fullWidthLink {
  width: 100%;
}

.btn-chevron-left {
  border: none;
  background-color: white;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.chipStyle {
  font-family: 'DM Sans', sans-serif !important;
  background-color: white !important;
}

.chipStyle:hover {
  background-color: #e0e0e0 !important;
}
